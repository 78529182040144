<template>
  <div class="main_content align-items-center main_selfassesment">
    <div class="top_heading mb_50">
      <div class="row justify-content-xl-between align-items-center">
        
        <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
          <div class="heading padd_ipad">
            <h2>Self-Assessment</h2>
            <h3>Program Manager Dashboard</h3>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
          <div class="align-items-center d-flex justify-content-end w-100">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar region_bar_add">
                <div class="row">
                  <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                    <div class="">
                      <div class="bg-white date-toolbar" style="box-shadow: none">
                        <div class="row">
                          <div class="col-xs-12 col-md-12 align-self-center">
                            <div class="row">
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date">
                                  <div class="heading_5">From</div>
                                  <div class="
                                      text_black
                                      font_size_14
                                      d-flex
                                      align-items-center
                                    ">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)" style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                      :format="customFormatter" @selected="changeStartDate"
                                      :disabledDates="disabledFutureDates">
                                    </datepicker>
                                    <p class="min-date-value d-none">
                                      {{ listConfig.start_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date todate pl_6">
                                  <div class="heading_5">To</div>
                                  <div class="
                                      text_black
                                      font_size_14
                                      d-flex
                                      align-items-center
                                    ">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)" style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                      :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                      :disabledDates="disabledDates"></datepicker>
                                    <p class="max-date-value d-none">
                                      {{ listConfig.end_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-3 fr_m align-self-center">
                    <div class="form-group for_ipad">
                      <v-select @input="getDashboardData" :reduce="(region_list) => region_list.id" :options="region_list"
                        label="name" placeholder="By Region" name="Region" v-model="listConfig.regionBy"
                        v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                      <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                        {{ errors.first("addUserForm.Region") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDatesMain">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="most_visited self_assesment">
      <div class="row">
        <div class="col-md-12 col-xl-7 col-xxl-6">
          <div class="row">
            <div class="col-xs-12 col-md-6 col-lg-6 col-xxl-5 lg-padding">
              <div class=" align-items-stretch both_card d-lg-flex justify-content-between ">
                <div class="card_box padd_card green_card mr_12">
                  <router-link :to="{
                      name: 'dashboard_survey_reports_SA',
                      params: { type: 'Self-Assessment' },
                    }" target="_blank">
                    <img :src="require('@/assets/images/self_user.png')" alt="" />
                    <h3>Completed Self- Assessments</h3>
                    <div class="flex">
                      <h2>{{ completed_self_assessment }}</h2>
                    </div>
                    <img :src="require('@/assets/images/green_graph.png')" alt="" class="bottom_graph" />
                  </router-link>
                </div>
                <div class="card_box padd_card orange_card">
                  <router-link :to="{
                    name: 'dashboard_scheduled_requests',
                      params: { type: 'Evaluations-Requested' },
                    }" target="_blank">
                  
                    <img :src="require('@/assets/images/notification_well.png')" alt="" />
                    <h3>1:1 Evaluations Requested</h3>
                    <div class="flex">
                      <h2>{{ evaluations_requested }}</h2>
                    </div>
                    <img :src="require('@/assets/images/orange_graph.png')" alt="" class="bottom_graph" />
                  </router-link>
                </div>
              </div>
              <div class="white_card m_and_c big_card mt_20 pb-3">
                <div class="card_box border-bot">
                  <h2>Reported Discomfort by Equipment</h2>
                  <div class="tab_report toggle_switch reported">
                    <ul class="nav nav-tabs" id="myTab1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="percent-tab1" data-bs-toggle="tab"
                          data-bs-target="#percent1" type="button" role="tab" aria-controls="percent"
                          aria-selected="true">
                          %
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="hash-tab1" data-bs-toggle="tab" data-bs-target="#hash1"
                          type="button" role="tab" aria-controls="hash" aria-selected="false">
                          #
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tab-content" id="myTab1Content">
                  <div class="tab-pane fade show active" id="percent1" role="tabpanel" aria-labelledby="percent-tab1">
                    <div v-if="discomfort_by_equipment.length" class="scrollable-section no-border">
                      <div v-for="(dbe, inx) in discomfort_by_equipment"
                        class="align-items-start d-flex w-100 withprogress" :key="inx">
                        <img :src="require('@/assets/images/' + dbe.image)" alt="" />
                        <div class="detailsProgress justify-content-between w-100">
                          <div class="
                              align-items-center
                              d-md-flex d-xxl-flex
                              justify-content-between
                              w-100
                            ">
                            <h2>{{ dbe.name }}</h2>
                            <h3>{{ dbe.percent }}%</h3>
                          </div>
                          <div class="progressBar">
                            <ul class="d-flex">
                              <li v-for="(level, indx) in dbe.levels" :class="level == '1' ? 'bg_green' : ''"
                                :key="indx"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="scrollable-section no-border">
                      <div class="align-items-start d-flex w-100 withprogress">
                        <div class="detailsProgress justify-content-between w-100">
                          <div class="align-items-center d-md-flex d-xxl-flex justify-content-between w-100 inc_height">
                            <h3 class="no_data">No Data Found</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="hash1" role="tabpanel" aria-labelledby="hash-tab1">
                    <div v-if="discomfort_by_equipment.length" class="scrollable-section no-border">
                      <div v-for="(dbe, inx) in discomfort_by_equipment"
                        class="align-items-start d-flex w-100 withprogress" :key="inx">
                        <img :src="require('@/assets/images/' + dbe.image)" alt="" />
                        <div class="detailsProgress justify-content-between w-100">
                          <div class="
                              align-items-center
                              d-md-flex d-xxl-flex
                              justify-content-between
                              w-100
                            ">
                            <h2>{{ dbe.name }}</h2>
                            <h3>{{ dbe.value }}</h3>
                          </div>
                          <div class="progressBar">
                            <ul class="d-flex">
                              <li v-for="(level, indx) in dbe.levels" :class="level == '1' ? 'bg_green' : ''"
                                :key="indx"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="scrollable-section no-border">
                      <div class="align-items-start d-flex w-100 withprogress">
                        <div class="detailsProgress justify-content-between w-100">
                          <div class="align-items-center d-md-flex d-xxl-flex justify-content-between w-100 inc_height">
                            <h3 class="no_data">No Data Found</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-6 col-xxl-7">
              <div class="white_card  big_card h-100 position-relative">
                <h2 class="p-3 px-4">Mitigation & Cost Savings</h2>
                <img  v-tooltip="{ ref: 'tooltipRef' }" :src="require('@/assets/images/info.png')" class="info_icon" alt="" />
                <div ref="tooltipRef" class="tooltip-content">
                  <p>1. Site to 1:1 Eval = Client users count - Users count with finalized ergo eval reports</p>
                  <p>2. Self-Assessment to Eval = Users count with self assessment attempted - Users count with finalized ergo eval reports.</p>
                </div>
                <div class="mitigation padd_mitigation border-dot px-3" style="border-top:1px solid #D6D5D5;">
                  <div class="d-flex align-items-start donut_chart">
                    <doughnut-chart :width="80" :height="80" :chart-data="mitisitedatacollection" ref="appendMitiSite">
                    </doughnut-chart>
                    <div class="
                      d-block  d-lg-flex d-xl-block d-xxl-flex
                        justify-content-between
                        w-100 ml_14">
                      <div class="miti-details less_miti">
                        <h2 v-tooltip="'Site to 1:1 Eval'" >Site to 1:1 Eval</h2>
                        <div class="tab-content" id="myTabMitiSiteContent">
                          <div class="tab-pane fade show active" id="percentMitiSite" role="tabpanel"
                            aria-labelledby="percent-tabMitiSite">
                            <h3>{{ mitigation_site_percent }}%</h3>
                          </div>
                          <div class="tab-pane fade" id="hashMitiSite" role="tabpanel"
                            aria-labelledby="hash-tabMitiSite">
                            <h3>{{ mitigation_site_count }}</h3>
                          </div>
                        </div>
                        
                      </div> 
                      <div class="tab_report toggle_switch text-end top_move">
                        <ul id="myTabMitiSite" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item">
                            <button id="percent-tabMitiSite" data-bs-toggle="tab" data-bs-target="#percentMitiSite"
                              type="button" role="tab" aria-controls="percent" aria-selected="true"
                              class="nav-link active">
                              %
                            </button>
                          </li>
                          <li role="presentation" class="nav-item">
                            <button id="hash-tabMitiSite" data-bs-toggle="tab" data-bs-target="#hashMitiSite"
                              type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">
                              #
                            </button>
                          </li>
                        </ul>
                        <h3 class="usd_rspns" >${{ mitigation_site_cost }} USD</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mitigation padd_mitigation border-dot px-3">
                  <div class="d-flex align-items-start donut_chart">
                    <doughnut-chart :width="80" :height="80" :chart-data="mitiselfdatacollection" ref="appendMitiSelf">
                    </doughnut-chart>
                    <div class="
                       d-block d-lg-flex d-xl-block d-xxl-flex
                        justify-content-between
                        w-100 ml_14">
                      <div class="miti-details less_miti">
                        <h2 v-tooltip="'Self-Assessment to Eval'">Self-Assessment to Eval</h2>
                        <div class="tab-content" id="myTabMitiSelfContent">
                          <div class="tab-pane fade show active" id="percentMitiSelf" role="tabpanel"
                            aria-labelledby="percent-tabMitiSelf">
                            <h3>{{ mitigation_selfAssessment_percent }}%</h3>
                          </div>
                          <div class="tab-pane fade" id="hashMitiSelf" role="tabpanel"
                            aria-labelledby="hash-tabMitiSelf">
                            <h3>{{ mitigation_selfAssessment_count }}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="tab_report toggle_switch text-end top_move">
                        <ul id="myTabMitiSelf" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item">
                            <button id="percent-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#percentMitiSelf"
                              type="button" role="tab" aria-controls="percent" aria-selected="true"
                              class="nav-link active">
                              %
                            </button>
                          </li>
                          <li role="presentation" class="nav-item">
                            <button id="hash-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#hashMitiSelf"
                              type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">
                              #
                            </button>
                          </li>
                        </ul>
                         <h3 class="usd_rspns" >${{ mitigation_selfAssessment_cost }} USD</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="mitigation padd_mitigation mb_cost px-4">
                  <h2>Cost Savings</h2>
                  <h1>${{ mitigation_cost_saving }} USD</h1>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-5 col-xxl-6 for-notepad for_no_top" ref="SAActivityByRegionDiv">
          <div class="activity_region">
            <div class="row">
              <div class="col-8 col-sm-6 col-md-7">
                <h2 class="mb_20">Self-Assessment Activity by Region </h2>
              </div>
              <div class="col-4 col-sm-6 col-md-5">
                <div class="visited_pages align-items-baseline d-flex justify-content-end">
                  <div class="d-none heading me-sm-3">
                    <div class="form-group by_min">
                      <v-select @input="filterActivityRegion" :reduce="(region_list) => region_list.id"
                        :options="region_list" label="name" placeholder="By Region" name="Region"
                        v-model="listConfig.region_regionBy" v-validate="'required'" />
                      <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                        {{ errors.first("addUserForm.Region") }}
                      </small>
                    </div>
                  </div>
                  <div v-if="hideActivityRegionDownloadBtn" class="d-flex justify-content-end ms-2 ms-sm-4">
                    <div data-html2canvas-ignore="true">
                      <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SAActivityByRegionDiv', 'SA-Activity-By-Region', '500px', '276px', 'SAActivityByRegionHeight', activity_region)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <g data-name="Group 12689">
                            <path data-name="Path 114" d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4" transform="translate(-3 -3)" style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mapping SAActivityByRegionHeight">
            <div v-show="activity_region.length" class="row mt-5 align-items-center">
              <div class="col-md-7 text-center">
                <div class="pieIchart">
                  <pie-chart  :height="300" ref="appendPieChart" :chart-data="piechartdatacollection"></pie-chart>
                </div>
              </div>
              <div class="col-md-5 align-self-center">
                <div class="row mb_32 justify-content-end">
                  <div class="col-9 text-end">
                    <div class="tab_report toggle_switch" data-html2canvas-ignore="true">
                      <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="percent-tab2" data-bs-toggle="tab"
                            data-bs-target="#percent2" type="button" role="tab" aria-controls="percent"
                            aria-selected="true">
                            %
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="hash-tab2" data-bs-toggle="tab" data-bs-target="#hash2"
                            type="button" role="tab" aria-controls="hash" aria-selected="false">
                            #
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-content" id="myTab2Content">
                  <div class="tab-pane fade show active" id="percent2" role="tabpanel" aria-labelledby="percent-tab2">
                    <div class="registrant cust_scroll ls_scl">
                      <div v-for="(acr, inx) in activity_region" class=" d-flex justify-content-between registrant_section " :key="inx">
                        <div class="name">
                          <h3>
                            <!-- <span v-if="inx==0" class="circle blue"></span>
                            <span v-if="inx==1" class="circle pink"></span>
                            <span v-if="inx==2" class="circle orange"></span>
                            <span v-if="inx==3" class="circle skyblue"></span>
                            <span v-if="inx==4" class="circle green"></span>
                            <span v-if="inx > 4" class="circle grey"></span> -->
                            <span class="circle" :style="'background-color:'+acr.code+';'"></span>
                            {{ acr.name }}
                          </h3>
                        </div>
                        <div class="percent">
                          <h3>{{ acr.percent }}%</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="hash2" role="tabpanel" aria-labelledby="hash-tab2">
                    <div class="registrant cust_scroll ls_scl">
                      <div v-for="(acr, inx) in activity_region" class=" d-flex justify-content-between registrant_section " :key="inx">
                        <div class="name">
                          <h3>
                            <!-- <span v-if="inx==0" class="circle blue"></span>
                            <span v-if="inx==1" class="circle pink"></span>
                            <span v-if="inx==2" class="circle orange"></span>
                            <span v-if="inx==3" class="circle skyblue"></span>
                            <span v-if="inx==4" class="circle green"></span>
                            <span v-if="inx > 4" class="circle grey"></span> -->
                            <span class="circle" :style="'background-color:'+acr.code+';'"></span>
                            {{ acr.name }}
                          </h3>
                        </div>
                        <div class="percent">
                          <h3>{{ acr.value }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!activity_region.length" class="row">
              <div class="col-md-12 text-center">
                <div class="align-items-center d-flex w-100 withprogress hgt_no_data white_card border_radius_20 mt-3">
                  <div class="detailsProgress justify-content-between w-100">
                    <div class="align-items-center d-md-flex d-xxl-flex justify-content-between w-100">
                      <h2 class="no_data">No Data Found</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self_report">
      <div class="row">
        <div class="col-xl-6 top_mob mt-5 mt-lg-0" ref="SelfReportedDiscomfortDiv">
          <div class="white_card border_radius_20">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-8 col-xs-8 col-sm-8 align-self-center">
                  <h2 class="mb_20">Self-Reported Discomfort</h2>
                </div>
                <div class="col-4 col-xs-4 col-sm-4 align-self-center text-end">
                  <div v-if="hideSelfReportedDownloadBtn" class="align-self-center ml_24" data-html2canvas-ignore="true">
                      <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('SelfReportedDiscomfortDiv', 'SA-Self-Reported-Discomfort', '', '', '', self_reported_discomfort)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <g data-name="Group 12689">
                            <path data-name="Path 114"
                              d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                              transform="translate(-3 -3)" style="
                                fill: none;
                                stroke: #a9a9a9;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 2px;
                              " />
                          </g>
                        </svg>
                      </a>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                
                <div v-show="self_reported_discomfort.length" class="stadn_graph px-3 scroll-chart cust_scroll">
                  <bar-chart :width="600" :height="300" ref="appendStandChart" :chart-data="selfdiscomfortdatacollection">
                  </bar-chart>
                </div>
              </div>
              <div class="tab-pane fade" id="Revisits" role="tabpanel" aria-labelledby="Revisits-tab">
                <div class="activity_region mb_10">
                  <div class="row">
                    <div class="col-md-7 align-self-center">
                      <div class="d-flex align-items-center">
                        <div class="new_user">
                          <a href="#" class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                              class="mr_10">
                              <g data-name="Group 12685" transform="translate(-1031 -251)">
                                <rect data-name="Rectangle 2213" width="25" height="25" rx="12.5"
                                  transform="translate(1031 251)" style="fill: #29cb97" />
                                <g data-name="Group 12684">
                                  <path data-name="Path 125"
                                    d="M9.714 8.573v1.194A3.429 3.429 0 0 0 5.143 13H4a4.571 4.571 0 0 1 5.714-4.428zm-1.143-.716A3.429 3.429 0 1 1 12 4.429a3.428 3.428 0 0 1-3.429 3.428zm0-1.143a2.286 2.286 0 1 0-2.285-2.285 2.285 2.285 0 0 0 2.285 2.285zM12 10.143V8.429h1.143v1.714h1.714v1.143h-1.714V13H12v-1.714h-1.714v-1.143z"
                                    transform="translate(1034 256.999)" style="fill: #fff" />
                                </g>
                              </g>
                            </svg>
                            New Users
                          </a>
                        </div>
                        <div class="value ml_20 font_size_20 font_bold">
                          <a href="#" class="">
                            50
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="15.271"
                              viewBox="0 0 12.828 15.271">
                              <path data-name="Path 79" d="m5 8 5-5m0 0 5 5m-5-5v12.857"
                                transform="translate(-3.586 -1.586)" style="
                                  fill: none;
                                  stroke: #29cb97;
                                  stroke-linecap: round;
                                  stroke-linejoin: round;
                                  stroke-width: 2px;
                                " />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 align-self-center text-lg-end">
                      <div class="visited_pages">
                        <div class="heading">
                          <div class="form-group mb-0">
                            <v-select v-model="selected" :options="['By Region', 'Client User']"
                              placeholder="By Region" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-end align-self-center">
                      <div class="d-flex justify-content-center">
                        <a href="http://">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g data-name="Group 12689">
                              <path data-name="Path 114"
                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                transform="translate(-3 -3)" style="
                                  fill: none;
                                  stroke: #a9a9a9;
                                  stroke-linecap: round;
                                  stroke-linejoin: round;
                                  stroke-width: 2px;
                                " />
                            </g>
                          </svg>
                        </a>
                        <a href="http://" class="ml_20">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path data-name="Path 130"
                              d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                              transform="translate(-2 -2)" style="
                                fill: none;
                                stroke: #a9a9a9;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 2px;
                              " />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="graph mb_34">
                  <img :src="require('@/assets/images/line_graph.png')" alt="graph" />
                </div>
              </div>
            </div>
            <div v-if="!self_reported_discomfort.length"
              class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class=" align-items-start d-md-flex d-xxl-flex justify-content-between w-100 h_120 h_100 ipad_mode">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="col-xl-6 mt-5 mt-xl-0" ref="EquipmentRecommendationsDiv">
          <div class="white_card border_radius_20">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-8 col-xs-8 col-sm-8 align-self-center">
                  <h2 class="mb_20">Equipment Recommendations</h2>
                </div>
                <div class="col-4 col-xs-4 col-sm-4 text-end">
                  <div v-if="hideEquipmentDownloadBtn" class="align-self-center ml_24" data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('EquipmentRecommendationsDiv', 'SA-Equipment-Recommendations', '', '', '', equipment_recommendations)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)" style="
                              fill: none;
                              stroke: #a9a9a9;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            " />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                
                <div v-show="equipment_recommendations.length" class="stadn_graph px-3 scroll-chart cust_scroll">
                  <bar-chart :width="600" :height="300" ref="appendEquipment" :chart-data="equipmentdatacollection"></bar-chart>
                </div>
              </div>
            </div>
            <div v-if="!equipment_recommendations.length"
              class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class="align-items-start d-md-flex d-xxl-flex justify-content-between w-100 h_120 h_100 ipad_mode">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self_report graphs-2 mt-0">
      <div class="row">
        <div class="col-xl-6 mt-5" ref="WorkAreaAssessedDiv">
          <div class="white_card border_radius_20 h-100">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-8 col-xs-12 col-sm-8 align-self-center">
                  <h2 class="mb_20 wid_work">Work Area Assessed</h2>
                </div>
                <div  class="col-4 col-xs-12 col-sm-4 text-end">
                  <div v-if="hideWorkAreaDownloadBtn" class="align-self-center ml_24" data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="prepareChart('WorkAreaAssessedDiv', 'SA-Work-Area-Assessed', '', '', '', work_area_assessed)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)" style="
                              fill: none;
                              stroke: #a9a9a9;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            " />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                <div v-show="work_area_assessed.length" class="stadn_graph px-3">
                  <bar-chart  :width="600" :height="300" ref="appendWorkArea" :chart-data="workareadatacollection"></bar-chart>
                </div>
              </div>
            </div>
            <div v-if="!work_area_assessed.length" class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class="align-items-center d-md-flex d-xxl-flex justify-content-between w-100 h_110 ipad_workareas">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 top_mob mt-5" ref="SelfReportedComfortLevelsDiv">
          <div class="white_card border_radius_20 h-100">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-8 col-xs-8 col-sm-10 align-self-center">
                  <h2 class="mb_20">Self-Reported Comfort Levels</h2>
                </div>
                 <div class="col-4 col-xs-4 col-sm-2 text-end align-self-center">
                   <div v-if="hideComfortLevelDownloadBtn" class="align-self-center ml_24 left_mg" data-html2canvas-ignore="true">
                      <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download" @click="
                          prepareChart('SelfReportedComfortLevelsDiv', 'SA-Self-Reported-Comfort-Levels', '', '', '', self_comfort_levels)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <g data-name="Group 12689">
                            <path data-name="Path 114" d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4" transform="translate(-3 -3)" style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                          </g>
                        </svg>
                      </a>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                <div class="activity_region mb_10 px-3 ipad_visited">
                  <div class="row">
                    <div class="col-sm-12 align-self-center text-lg-end">
                      <div class="d-flex justify-content-end align-items-baseline">
                        <div class="d-none visited_pages ipad_visited">
                          <div class="heading">
                            <div class="form-group mb-0 by_min">
                              <v-select @input="filterComfortLevel" :reduce="(region_list) => region_list.id"
                                :options="region_list" label="name" placeholder="By Region" name="Region"
                                v-model="listConfig.comfortLevel_regionBy" v-validate="'required'" />
                              <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                {{ errors.first("addUserForm.Region") }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="self_comfort_levels.length" class="bar_graph px-3">
                  <bar-chart  :width="600" :height="600" ref="appendSelfComfort" :chart-data="selfcomfortdatacollection"></bar-chart>
                </div>
              </div>
            </div>
            <div v-if="!self_comfort_levels.length" class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class="align-items-center d-md-flex d-xxl-flex justify-content-between w-100 h_110 ipad_workareas">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade box_up download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="downloadLabel">
                <h3 class="heading_2">Download</h3>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-around">
                <div class="col-md-3">
                  <div class="image_upload text-center downloadDocumentClass disabled">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('document')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42" class="d-block">
                        <path data-name="Path 122" d="M13.889 23h13.333m-13.333 8.889h13.333M31.667 43H9.444A4.444 4.444 0 0 1 5 38.556V7.444A4.444 4.444 0 0 1 9.444 3h12.414a2.222 2.222 0 0 1 1.571.651L35.46 15.682a2.222 2.222 0 0 1 .651 1.571v21.3A4.444 4.444 0 0 1 31.667 43z" transform="translate(-4 -2)" style="
                          fill:none;
                          stroke-linecap:round;
                          stroke-linejoin:round;
                          stroke-width:2px
                        " />
                      </svg>
                      Download as a document
                    </a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="image_upload text-center downloadImageClass">
                    <a href="javascript:" class="text-center" @click="enableDownloadBtn('image')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063" viewBox="0 0 38.891 38.063" class="d-block">
                        <path data-name="Path 143"
                          d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                          transform="translate(-2.586 -3)" style="
                            fill: none;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          " />
                      </svg>
                      Download as an image
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-top-0 mx-auto mb_50">
              <button type="button" class="btn btn_primary_light mr_30" data-bs-dismiss="modal"> Close </button>
              <button type="button" class="btn btn_primary sml_download" @click="downloadChart()">Download</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import $ from "jquery";
  import moment from "moment";
  import "vue-select/dist/vue-select.css";
  import Datepicker from "vuejs-datepicker";
  import PieChart from "../../components/charts/PieChart.js";
  import BarChart from "../../components/charts/BarChart.js";
  import DoughnutChart from "../../components/charts/DoughnutChart.js";
  // import jsPDF from 'jspdf';
  import html2Canvas from 'html2canvas';
  import { utils, writeFileXLSX } from 'xlsx';
  import commonFunction from "../../mixin/commonFunction";

  export default {
    name: "my_reports",
    components: {
      Datepicker,
      PieChart,
      BarChart,
      DoughnutChart,
    },
    mixins: [commonFunction],
    data() {
      return {
        btnText: '',
        show: false,
        active: false,
        setChartWidth: '',
        doughnut_chart_options:  {
          tooltips: {enabled: false},
          hover: {mode: null},
        },
        chart_options:  {
          legend: { display: false },
          cornerRadius: 7,
        },
        barChartOptions : {
          legend: { display: false },
          responsive:window.innerWidth < 767 ? false : true,
          cornerRadius: 7,
        },
        mitisitedatacollection: {
          datasets: [{
            backgroundColor: ['#4880FF', '#4880FF4D'],
            data: [0,100]
          }]
        },
        mitiselfdatacollection: {
          datasets: [{
            backgroundColor: ['#4880FF', '#4880FF4D'],
            data: [0,100]
          }]
        },
        piechartdatacollection: {
          labels: [],
          datasets: [{
            backgroundColor: [],
            data: [],
          }, ],
        },
        selfdiscomfortdatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Self-Reported Discomfort",
            backgroundColor: [],
            maxBarThickness: 40,
          }, ],
        },
        equipmentdatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Equipment Recommendations",
            backgroundColor: [],
            maxBarThickness: 40,
          }, ],
        },
        workareadatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Work Area Assessed",
            backgroundColor: [
              "#4880FF",
              "#93E4CA",
              "#FFC9AE",
              "#FDDF7F",
              "#F3C695",
            ],
            maxBarThickness: 40,
          }, ],
        },
        selfcomfortdatacollection: {
          labels: [],
          datasets: [{
            data: [],
            label: "Self-Reported Comfort Levels",
            backgroundColor: ["#4880FF", "#29CB96", "#93E4CA", "#FFC9AE"],
            maxBarThickness: 40,
          }, ],
        },
        region_list: [{
          id: 'ALL',
          name: 'ALL',
        }],
        disabledDates: {
          to: new Date(Date.now() - 8640000),
        },
        disabledFutureDates: {
          from: new Date(Date.now()),
        },
        listConfig: {
          typeOfAssessment: "Self-Assessment",
          //Main filter...
          start_date: null,
          end_date: null,
          regionBy: null,
          //Activity Region filter...
          region_sdate: null,
          region_edate: null,
          region_regionBy: null,
          //Self-Reported Discomfort filter...
          discomfort_sdate: null,
          discomfort_edate: null,
          discomfort_regionBy: null,
          //Equipment Recommendations filter...
          equipment_sdate: null,
          equipment_edate: null,
          equipment_regionBy: null,
          //Work Area Assessed filter...
          workArea_sdate: null,
          workArea_edate: null,
          workArea_regionBy: null,
          //Self-Reported Comfort Levels filter...
          comfortLevel_sdate: null,
          comfortLevel_edate: null,
          comfortLevel_regionBy: null,
        },
        data_length: 0,
        selected: "",
        isDisabled: "",
        isDisabledRegion: "",
        isDisabledDiscomfort: "",
        isDisabledEquipment: "",
        isDisabledWorkArea: "",
        isDisabledComfortLevel: "",
        classDiscomfort: false,
        classEquipment: false,
        classWorkArea: false,

        completed_self_assessment: 0,
        evaluations_requested: 0,
        discomfort_by_equipment: [],
        mitigation_site_cost: 0,
        mitigation_site_count: 0,
        mitigation_site_percent: 0,
        mitigation_selfAssessment_cost: 0,
        mitigation_selfAssessment_count: 0,
        mitigation_selfAssessment_percent: 0,
        mitigation_cost_saving: 0,
        activity_region_CSV: [],
        activity_region: [],
        hideActivityRegionDownloadBtn: true,
        hideSelfReportedDownloadBtn: true,
        hideEquipmentDownloadBtn: true,
        hideWorkAreaDownloadBtn: true,
        hideComfortLevelDownloadBtn: true,
        self_reported_discomfortCSV: [],
        self_reported_discomfort: [],
        equipment_recommendationsCSV: [],
        equipment_recommendations: [],
        work_area_assessed_HomeCSV: [],
        work_area_assessed_LabCSV: [],
        work_area_assessed_OfficeCSV: [],
        work_area_assessed_AnywhereCSV: [],
        work_area_assessed: [],
        self_comfort_levels_ComCSV: [],
        self_comfort_levels_DisCSV: [],
        self_comfort_levels: [],
        downloadRef: '',
        downloadType: 'image',
        downloadImageName: '',
        downloadMaxHeight: '',
        downloadMinHeight: '',
        downloadHeightClass: '',
        downloadCSVData: '',
        rowsData: [],
        previousStartDate: null,
      };
    },
    destroyed() {
      this.$parent.employeeDashboard = false;
    },
    mounted() {
      document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
      this.$parent.employeeDashboard = true;
      //Get all regions from Client data...
      var clientData = this.$store.getters["auth/authUser"];
      var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
      var clientRegionsArr = clientRegions.split(",");
      for (let i = 0; i < clientRegionsArr.length; i++) {
        this.region_list.push({"id": clientRegionsArr[i], "name": clientRegionsArr[i]});
      }
      this.listConfig.start_date = "";
        this.listConfig.end_date = "";
        this.listConfig.regionBy = "";
        this.listConfig.region_sdate = "";
        this.listConfig.region_edate = "";
        this.listConfig.region_regionBy = "";
        this.listConfig.discomfort_sdate = "";
        this.listConfig.discomfort_edate = "";
        this.listConfig.discomfort_regionBy = "";
        this.listConfig.equipment_sdate = "";
        this.listConfig.equipment_edate = "";
        this.listConfig.equipment_regionBy = "";
        this.listConfig.workArea_sdate = "";
        this.listConfig.workArea_edate = "";
        this.listConfig.workArea_regionBy = "";
        this.listConfig.comfortLevel_sdate = "";
        this.listConfig.comfortLevel_edate = "";
        this.listConfig.comfortLevel_regionBy = "";
        this.isDisabled = true;
        this.isDisabledRegion = true;
        this.isDisabledDiscomfort = true;
        this.isDisabledEquipment = true;
        this.isDisabledWorkArea = true;
        this.isDisabledComfortLevel = true;

        this.classWorkArea = false;
        this.classEquipment = false;
        this.classDiscomfort = false;
        this.getDashboardData();
    },
    methods: {
      prepareChart(ref, imageName, maxHeight, minHeight, heightClass, csvData) {
        this.downloadRef = ref;
        this.downloadImageName = imageName;
        this.downloadMaxHeight = maxHeight;
        this.downloadMinHeight = minHeight;
        this.downloadHeightClass = heightClass;
        this.downloadCSVData = csvData;
        this.enableDownloadBtn('image');
      },
      enableDownloadBtn(btnType) {
        if(btnType == 'image') {
          $('.downloadImageClass').removeClass('disabled');
          $('.downloadDocumentClass').addClass('disabled');
        }
        else if(btnType == 'document') {
          $('.downloadDocumentClass').removeClass('disabled');
          $('.downloadImageClass').addClass('disabled');
        }
        this.downloadType = btnType;
      },
      async downloadChart() {
        var _this = this;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        //Audit log entry  
        var sectionName = '';
        switch(_this.downloadRef) {
          case 'SAActivityByRegionDiv':
            sectionName = 'Self-Assessment Activity by Region';
            break;
          case 'SelfReportedDiscomfortDiv':
            sectionName = 'Self-Reported Discomfort';
            break;
          case 'EquipmentRecommendationsDiv':
            sectionName = 'Equipment Recommendations';
            break;
          case 'WorkAreaAssessedDiv':
            sectionName = 'Work Area Assessed';
            break;
          case 'SelfReportedComfortLevelsDiv':
            sectionName = 'Self-Reported Comfort Levels';
            break;
        } 
        var requestData = {'section': sectionName,'dashboard':'Self-Assessment'};
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        }).catch(({ response }) => {
          if (response.status == 401) {
            _this.tokenExpired();
          }
        });
        if(_this.downloadHeightClass != '') {
          $('.cust_scroll').css({'overflow': 'unset'});
          $('.'+_this.downloadHeightClass).css({'height': _this.downloadMaxHeight});
        }
        $('.white_card').css({'box-shadow': 'unset'});
        const el =  _this.$refs[_this.downloadRef];
        const options = {
            type: "dataURL",
            scrollX: 0,
            scrollY: -window.scrollY
        };
        const printCanvas = await html2Canvas(el, options);
        setTimeout(() => {
          const link = document.createElement("a");
          if(_this.downloadType == 'image'){
              link.setAttribute("download", _this.downloadImageName + '.png');
              link.setAttribute(
                  "href",
                  printCanvas
                  .toDataURL("image/png")
                  .replace("image/png", "image/octet-stream")
              );
            link.click();
          }
          else if(_this.downloadType == 'document')
          {
            var xlsxSheetData1 = ''; var xlsxSheetName1 = '';
            var xlsxSheetData2 = ''; var xlsxSheetName2 = '';
            var xlsxSheetData3 = ''; var xlsxSheetName3 = '';
            var xlsxSheetData4 = ''; var xlsxSheetName4 = '';
            var xlsxSheetData5 = ''; var xlsxSheetName5 = '';
            var xlsxSheetData6 = ''; var xlsxSheetName6 = '';
            var xlsxSheetData7 = ''; var xlsxSheetName7 = '';
            var xlsxSheetData8 = ''; var xlsxSheetName8 = '';
            var xlsxSheetData9 = ''; var xlsxSheetName9 = '';
            var xlsxSheetData10 = ''; var xlsxSheetName10 = '';
            var xlsxSheetData11 = ''; var xlsxSheetName11 = '';
            var xlsxSheetData12 = ''; var xlsxSheetName12 = '';
            var xlsxSheetData13 = ''; var xlsxSheetName13 = '';
            var xlsxSheetData14 = ''; var xlsxSheetName14 = '';
            var xlsxSheetData15 = ''; var xlsxSheetName15 = '';
            var xlsxSheetData16 = ''; var xlsxSheetName16 = '';
            var xlsxSheetData17 = ''; var xlsxSheetName17 = '';
            var xlsxSheetData18 = ''; var xlsxSheetName18 = '';
            var xlsxSheetData19 = ''; var xlsxSheetName19 = '';
            var xlsxSheetData20 = ''; var xlsxSheetName20 = '';
            var xlsxSheetData21 = ''; var xlsxSheetName21 = '';
            var xlsxSheetData22 = ''; var xlsxSheetName22 = '';
            var xlsxSheetData23 = ''; var xlsxSheetName23 = '';
            var xlsxSheetData24 = ''; var xlsxSheetName24 = '';
            var xlsxSheetData25 = ''; var xlsxSheetName25 = '';
            var xlsxSheetData26 = ''; var xlsxSheetName26 = '';
            var xlsxSheetData27 = ''; var xlsxSheetName27 = '';
            var xlsxSheetData28 = ''; var xlsxSheetName28 = '';
            var xlsxSheetData29 = ''; var xlsxSheetName29 = '';
            var xlsxSheetData30 = ''; var xlsxSheetName30 = '';
            if(_this.downloadRef == 'SAActivityByRegionDiv')
            {
              if(_this.activity_region_CSV.length) {
                _this.activity_region_CSV.forEach(function (item, indx) {
                  if(indx == 0) {
                    xlsxSheetName1 = item[0].Region+' Region';
                    xlsxSheetData1 = utils.json_to_sheet(item);
                  }
                  else if(indx == 1) {
                    xlsxSheetName2 = item[0].Region+' Region';
                    xlsxSheetData2 = utils.json_to_sheet(item);
                  }
                  else if(indx == 2) {
                    xlsxSheetName3 = item[0].Region+' Region';
                    xlsxSheetData3 = utils.json_to_sheet(item);
                  }
                  else if(indx == 3) {
                    xlsxSheetName4 = item[0].Region+' Region';
                    xlsxSheetData4 = utils.json_to_sheet(item);
                  }
                  else if(indx == 4) {
                    xlsxSheetName5 = item[0].Region+' Region';
                    xlsxSheetData5 = utils.json_to_sheet(item);
                  }
                  else if(indx == 5) {
                    xlsxSheetName6 = item[0].Region+' Region';
                    xlsxSheetData6 = utils.json_to_sheet(item);
                  }
                  else if(indx == 6) {
                    xlsxSheetName7 = item[0].Region+' Region';
                    xlsxSheetData7 = utils.json_to_sheet(item);
                  }
                  else if(indx == 7) {
                    xlsxSheetName8 = item[0].Region+' Region';
                    xlsxSheetData8 = utils.json_to_sheet(item);
                  }
                  else if(indx == 8) {
                    xlsxSheetName9 = item[0].Region+' Region';
                    xlsxSheetData9 = utils.json_to_sheet(item);
                  }
                  else if(indx == 9) {
                    xlsxSheetName10 = item[0].Region+' Region';
                    xlsxSheetData10 = utils.json_to_sheet(item);
                  }
                  else if(indx == 10) {
                    xlsxSheetName11 = item[0].Region+' Region';
                    xlsxSheetData11 = utils.json_to_sheet(item);
                  }
                  else if(indx == 11) {
                    xlsxSheetName12 = item[0].Region+' Region';
                    xlsxSheetData12 = utils.json_to_sheet(item);
                  }
                  else if(indx == 12) {
                    xlsxSheetName13 = item[0].Region+' Region';
                    xlsxSheetData13 = utils.json_to_sheet(item);
                  }
                  else if(indx == 13) {
                    xlsxSheetName14 = item[0].Region+' Region';
                    xlsxSheetData14 = utils.json_to_sheet(item);
                  }
                  else if(indx == 14) {
                    xlsxSheetName15 = item[0].Region+' Region';
                    xlsxSheetData15 = utils.json_to_sheet(item);
                  }
                  else if(indx == 15) {
                    xlsxSheetName16 = item[0].Region+' Region';
                    xlsxSheetData16 = utils.json_to_sheet(item);
                  }
                  else if(indx == 16) {
                    xlsxSheetName17 = item[0].Region+' Region';
                    xlsxSheetData17 = utils.json_to_sheet(item);
                  }
                  else if(indx == 17) {
                    xlsxSheetName18 = item[0].Region+' Region';
                    xlsxSheetData18 = utils.json_to_sheet(item);
                  }
                  else if(indx == 18) {
                    xlsxSheetName19 = item[0].Region+' Region';
                    xlsxSheetData19 = utils.json_to_sheet(item);
                  }
                  else if(indx == 19) {
                    xlsxSheetName20 = item[0].Region+' Region';
                    xlsxSheetData20 = utils.json_to_sheet(item);
                  }
                  else if(indx == 20) {
                    xlsxSheetName21 = item[0].Region+' Region';
                    xlsxSheetData21 = utils.json_to_sheet(item);
                  }
                  else if(indx == 21) {
                    xlsxSheetName22 = item[0].Region+' Region';
                    xlsxSheetData22 = utils.json_to_sheet(item);
                  }
                  else if(indx == 22) {
                    xlsxSheetName23 = item[0].Region+' Region';
                    xlsxSheetData23 = utils.json_to_sheet(item);
                  }
                  else if(indx == 23) {
                    xlsxSheetName24 = item[0].Region+' Region';
                    xlsxSheetData24 = utils.json_to_sheet(item);
                  }
                  else if(indx == 24) {
                    xlsxSheetName25 = item[0].Region+' Region';
                    xlsxSheetData25 = utils.json_to_sheet(item);
                  }
                  else if(indx == 25) {
                    xlsxSheetName26 = item[0].Region+' Region';
                    xlsxSheetData26 = utils.json_to_sheet(item);
                  }
                  else if(indx == 26) {
                    xlsxSheetName27 = item[0].Region+' Region';
                    xlsxSheetData27 = utils.json_to_sheet(item);
                  }
                  else if(indx == 27) {
                    xlsxSheetName28 = item[0].Region+' Region';
                    xlsxSheetData28 = utils.json_to_sheet(item);
                  }
                  else if(indx == 28) {
                    xlsxSheetName29 = item[0].Region+' Region';
                    xlsxSheetData29 = utils.json_to_sheet(item);
                  }
                  else if(indx == 29) {
                    xlsxSheetName30 = item[0].Region+' Region';
                    xlsxSheetData30 = utils.json_to_sheet(item);
                  }
                });
              }
            }
            else if(_this.downloadRef == 'SelfReportedDiscomfortDiv')
            {
              if(_this.self_reported_discomfortCSV.length) {
                xlsxSheetName1 = 'Self-Reported Discomforts';
                xlsxSheetData1 = utils.json_to_sheet(_this.self_reported_discomfortCSV);
              }
            }
            else if(_this.downloadRef == 'EquipmentRecommendationsDiv')
            {
              if(_this.equipment_recommendationsCSV.length) {
                xlsxSheetName1 = 'Equipment Recommendations';
                xlsxSheetData1 = utils.json_to_sheet(_this.equipment_recommendationsCSV);
              }
            }
            else if(_this.downloadRef == 'WorkAreaAssessedDiv')
            {
              if(_this.work_area_assessed_HomeCSV.length) {
                xlsxSheetName1 = 'Work Area Assessed Home';
                xlsxSheetData1 = utils.json_to_sheet(_this.work_area_assessed_HomeCSV);
              }
              if(_this.work_area_assessed_LabCSV.length) {
                xlsxSheetName2 = 'Work Area Assessed Lab';
                xlsxSheetData2 = utils.json_to_sheet(_this.work_area_assessed_LabCSV);
              }
              if(_this.work_area_assessed_OfficeCSV.length) {
                xlsxSheetName3 = 'Work Area Assessed Office';
                xlsxSheetData3 = utils.json_to_sheet(_this.work_area_assessed_OfficeCSV);
              }
              if(_this.work_area_assessed_AnywhereCSV.length) {
                xlsxSheetName4 = 'Work Area Assessed Anywhere';
                xlsxSheetData4 = utils.json_to_sheet(_this.work_area_assessed_AnywhereCSV);
              }
            }
            else if(_this.downloadRef == 'SelfReportedComfortLevelsDiv')
            {
              if(_this.self_comfort_levels_ComCSV.length) {
                xlsxSheetName1 = 'Self-Reported Comfort Levels';
                xlsxSheetData1 = utils.json_to_sheet(_this.self_comfort_levels_ComCSV);
              }
              if(_this.self_comfort_levels_DisCSV.length) {
                xlsxSheetName2 = 'Self-Reported Discomfort Levels';
                xlsxSheetData2 = utils.json_to_sheet(_this.self_comfort_levels_DisCSV);
              }
            }
            // A workbook is the name given to an Excel file
            var wb = utils.book_new(); // make Workbook of Excel
            // add Worksheet to Workbook // Workbook contains one or more worksheets
            if(xlsxSheetData1) {
              utils.book_append_sheet(wb, xlsxSheetData1, xlsxSheetName1);
            }
            if(xlsxSheetData2) {
              utils.book_append_sheet(wb, xlsxSheetData2, xlsxSheetName2);   
            }
            if(xlsxSheetData3) {
              utils.book_append_sheet(wb, xlsxSheetData3, xlsxSheetName3);   
            }
            if(xlsxSheetData4) {
              utils.book_append_sheet(wb, xlsxSheetData4, xlsxSheetName4);   
            }
            if(xlsxSheetData5) {
              utils.book_append_sheet(wb, xlsxSheetData5, xlsxSheetName5);   
            }
            if(xlsxSheetData6) {
              utils.book_append_sheet(wb, xlsxSheetData6, xlsxSheetName6);   
            }
            if(xlsxSheetData7) {
              utils.book_append_sheet(wb, xlsxSheetData7, xlsxSheetName7);   
            }
            if(xlsxSheetData8) {
              utils.book_append_sheet(wb, xlsxSheetData8, xlsxSheetName8);   
            }
            if(xlsxSheetData9) {
              utils.book_append_sheet(wb, xlsxSheetData9, xlsxSheetName9);   
            }
            if(xlsxSheetData10) {
              utils.book_append_sheet(wb, xlsxSheetData10, xlsxSheetName10);   
            }
            if(xlsxSheetData11) {
              utils.book_append_sheet(wb, xlsxSheetData11, xlsxSheetName11);   
            }
            if(xlsxSheetData12) {
              utils.book_append_sheet(wb, xlsxSheetData12, xlsxSheetName12);   
            }
            if(xlsxSheetData13) {
              utils.book_append_sheet(wb, xlsxSheetData13, xlsxSheetName13);   
            }
            if(xlsxSheetData14) {
              utils.book_append_sheet(wb, xlsxSheetData14, xlsxSheetName14);   
            }
            if(xlsxSheetData15) {
              utils.book_append_sheet(wb, xlsxSheetData15, xlsxSheetName15);   
            }
            if(xlsxSheetData16) {
              utils.book_append_sheet(wb, xlsxSheetData16, xlsxSheetName16);   
            }
            if(xlsxSheetData17) {
              utils.book_append_sheet(wb, xlsxSheetData17, xlsxSheetName17);   
            }
            if(xlsxSheetData18) {
              utils.book_append_sheet(wb, xlsxSheetData18, xlsxSheetName18);   
            }
            if(xlsxSheetData19) {
              utils.book_append_sheet(wb, xlsxSheetData19, xlsxSheetName19);   
            }
            if(xlsxSheetData20) {
              utils.book_append_sheet(wb, xlsxSheetData20, xlsxSheetName20);   
            }
            if(xlsxSheetData21) {
              utils.book_append_sheet(wb, xlsxSheetData21, xlsxSheetName21);   
            }
            if(xlsxSheetData22) {
              utils.book_append_sheet(wb, xlsxSheetData22, xlsxSheetName22);   
            }
            if(xlsxSheetData23) {
              utils.book_append_sheet(wb, xlsxSheetData23, xlsxSheetName23);   
            }
            if(xlsxSheetData24) {
              utils.book_append_sheet(wb, xlsxSheetData24, xlsxSheetName24);   
            }
            if(xlsxSheetData25) {
              utils.book_append_sheet(wb, xlsxSheetData25, xlsxSheetName25);   
            }
            if(xlsxSheetData26) {
              utils.book_append_sheet(wb, xlsxSheetData26, xlsxSheetName26);   
            }
            if(xlsxSheetData27) {
              utils.book_append_sheet(wb, xlsxSheetData27, xlsxSheetName27);   
            }
            if(xlsxSheetData28) {
              utils.book_append_sheet(wb, xlsxSheetData28, xlsxSheetName28);   
            }
            if(xlsxSheetData29) {
              utils.book_append_sheet(wb, xlsxSheetData29, xlsxSheetName29);   
            }
            if(xlsxSheetData30) {
              utils.book_append_sheet(wb, xlsxSheetData30, xlsxSheetName30);   
            }
            // export Excel file
            if(wb.SheetNames.length) {
              writeFileXLSX(wb, _this.downloadImageName + '.xlsx') // name of the file is 'book.xlsx'
            }
            ///////////// Commented PDF Code //////////////
            // //Adjust width and height...
            // var topMargin = 20;
            // var leftMargin = 10;
            // var imgWidth =  (printCanvas.width * 60) / 240;
            // var imgHeight = (printCanvas.height * 60) / 240;
            // if(imgWidth < 100){
            //   leftMargin = 50;
            // }
            // else if(imgWidth < 130 && imgWidth > 100){
            //   leftMargin = 30;
            // }
            // else if(imgWidth < 160 && imgWidth > 130){
            //   leftMargin = 20;
            // }
            // //jsPDF changes...
            // var pdf = new jsPDF('p', 'mm', 'a4');
            // pdf.addImage(printCanvas, 'png', leftMargin, topMargin, imgWidth, imgHeight); // 2: 19
            // pdf.save(_this.downloadImageName+`${$('.pdf-title').text()}.pdf`);
          }
          if(_this.downloadHeightClass != '') {
            $('.cust_scroll').css({'overflow': 'auto'});
            $('.'+_this.downloadHeightClass).css({'height': _this.downloadMinHeight});
          }
          $('.white_card').css({'box-shadow': '5px 10px 20px #f198361a'});
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          document.getElementsByClassName("btn-close")[0].click();
        }, 100);
      },
      exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
        }
        else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      resetDatesMain() {
        // if ((this.listConfig.end_date != "" && this.listConfig.end_date != null) || this.listConfig.regionBy != "") {
        this.listConfig.start_date = "";
        this.listConfig.end_date = "";
        this.listConfig.regionBy = "";
        this.listConfig.region_sdate = "";
        this.listConfig.region_edate = "";
        this.listConfig.region_regionBy = "";
        this.listConfig.discomfort_sdate = "";
        this.listConfig.discomfort_edate = "";
        this.listConfig.discomfort_regionBy = "";
        this.listConfig.equipment_sdate = "";
        this.listConfig.equipment_edate = "";
        this.listConfig.equipment_regionBy = "";
        this.listConfig.workArea_sdate = "";
        this.listConfig.workArea_edate = "";
        this.listConfig.workArea_regionBy = "";
        this.listConfig.comfortLevel_sdate = "";
        this.listConfig.comfortLevel_edate = "";
        this.listConfig.comfortLevel_regionBy = "";
        this.isDisabled = true;
        this.isDisabledRegion = true;
        this.isDisabledDiscomfort = true;
        this.isDisabledEquipment = true;
        this.isDisabledWorkArea = true;
        this.isDisabledComfortLevel = true;

        this.classWorkArea = false;
        this.classEquipment = false;
        this.classDiscomfort = false;
        this.getDashboardData();
      },
      getDashboardData() {
        this.filterMainData();
        this.filterActivityRegion();
        this.filterSelfDiscomfort();
        this.filterEquipmentData();
        this.filterWorkArea();
        this.filterComfortLevel();
      },
      filterMainData() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        _this.assignDefaultValues();
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/dashboard-search",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data:  {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              this.classWorkArea = true;
              this.classEquipment = true;
              this.classDiscomfort = true;

               var decodedJson = _this.decodeAPIResponse(data.records.data);
              _this.completed_self_assessment  = decodedJson.completed_self_assessment;
              _this.evaluations_requested  = decodedJson.evaluations_requested;
              // //Mitigation & Cost Savings...
              _this.mitigation_site_cost  = decodedJson.mitigation_site_cost;
              _this.mitigation_site_count  = decodedJson.mitigation_site_count;
              _this.mitigation_site_percent  = decodedJson.mitigation_site_percent;
              _this.mitigation_selfAssessment_cost  = decodedJson.mitigation_selfAssessment_cost;
              _this.mitigation_selfAssessment_count  = decodedJson.mitigation_selfAssessment_count;
              _this.mitigation_selfAssessment_percent  = decodedJson.mitigation_selfAssessment_percent;
              _this.mitigation_cost_saving  = decodedJson.mitigation_cost_saving;
              _this.mitisitedatacollection.datasets[0].data = [];
              _this.mitisitedatacollection.datasets[0].data.push(_this.mitigation_site_percent);
              _this.mitisitedatacollection.datasets[0].data.push((100 - _this.mitigation_site_percent));
              _this.mitiselfdatacollection.datasets[0].data = [];
              _this.mitiselfdatacollection.datasets[0].data.push(_this.mitigation_selfAssessment_percent);
              _this.mitiselfdatacollection.datasets[0].data.push((100 - _this.mitigation_selfAssessment_percent));
              setTimeout(() => {
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);
              //Reported Discomfort by Equipment...
              _this.discomfort_by_equipment  = decodedJson.discomfort_by_equipment;
            }
            else {
              _this.completed_self_assessment = "0";
              _this.evaluations_requested = "0";
              //Reported Discomfort by Equipment...
              _this.discomfort_by_equipment = [];  
              //Mitigation & Cost Savings...
              _this.mitigation_site_cost = 0;
              _this.mitigation_site_count = 0;
              _this.mitigation_site_percent = 0;
              _this.mitigation_selfAssessment_cost = 0;
              _this.mitigation_selfAssessment_count = 0;
              _this.mitigation_selfAssessment_percent = 0;
              _this.mitigation_cost_saving = 0;
              _this.mitisitedatacollection.datasets[0].data = [0,100];
              _this.mitiselfdatacollection.datasets[0].data = [0,100];
             
              setTimeout(() => {
                //Mitigation & Cost Savings Site Eval...
                _this.$refs.appendMitiSite.renderChart(
                  _this.mitisitedatacollection,_this.doughnut_chart_options
                );
                //Mitigation & Cost Savings Self-Assessment...
                _this.$refs.appendMitiSelf.renderChart(
                  _this.mitiselfdatacollection,_this.doughnut_chart_options
                );
              }, 100);           
            }
            
          }).catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }
            else if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }
            else if (response.status == 401) {
              _this.tokenExpired();
            }
          });
      },
      filterActivityRegion() {
        var _this = this;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/activity-by-region",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              var decodedJson = _this.decodeAPIResponse(data.records.data);
              //Self-Assessment Activity by Region...
              _this.piechartdatacollection.labels = [];
              _this.piechartdatacollection.datasets[0].data = [];
              _this.piechartdatacollection.datasets[0].backgroundColor = [];
              _this.activity_region_CSV = decodedJson.activity_region_CSV;
              _this.activity_region = decodedJson.activity_region;
              _this.activity_region.forEach(function (item) {
                _this.piechartdatacollection.labels.push(item.name+' ('+item.percent+'%)');
                _this.piechartdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
                _this.piechartdatacollection.datasets[0].backgroundColor.push(item.code);
              });
            }
            else {
              _this.activity_region_CSV = [];
              _this.activity_region = [];
              _this.piechartdatacollection.labels = [];
              _this.piechartdatacollection.datasets[0].data = [];
              _this.piechartdatacollection.datasets[0].backgroundColor = [];
            }
            setTimeout(() => {
              if(_this.activity_region.length) {
                _this.hideActivityRegionDownloadBtn = true;
              }
              else {
                _this.hideActivityRegionDownloadBtn = false;
              }
              _this.$refs.appendPieChart.renderChart(
                _this.piechartdatacollection,_this.chart_options
              );
            }, 100);
          })
          .catch(() => {
            //
          });
      },
      filterSelfDiscomfort() {
        var _this = this;
        if (_this.listConfig.discomfort_edate != "" || _this.listConfig.discomfort_regionBy != "") {
          _this.classDiscomfort = true;
        }
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/self-reported-discomfort",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              var decodedJson = _this.decodeAPIResponse(data.records.data);

              //Self-Reported Discomfort...
              _this.selfdiscomfortdatacollection.labels = [];
              _this.selfdiscomfortdatacollection.datasets[0].data = [];
              _this.selfdiscomfortdatacollection.datasets[0].backgroundColor = [];
              _this.self_reported_discomfortCSV = decodedJson.self_reported_discomfortCSV;
              _this.self_reported_discomfort = decodedJson.self_reported_discomfort;
              _this.self_reported_discomfort.forEach(function (item) {
                // _this.selfdiscomfortdatacollection.labels.push(item.name + " (" + item.percent + "%)");
                _this.selfdiscomfortdatacollection.labels.push(item.name);
                _this.selfdiscomfortdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
                _this.selfdiscomfortdatacollection.datasets[0].backgroundColor.push("#F3C695");
              });
              _this.selfdiscomfortdatacollection.datasets[0].data.push(0);
            }
            else {
              _this.self_reported_discomfortCSV = [];
              _this.self_reported_discomfort = [];
              _this.selfdiscomfortdatacollection.labels = [];
              _this.selfdiscomfortdatacollection.datasets[0].data = [];
              _this.selfdiscomfortdatacollection.datasets[0].backgroundColor = [];
            }
            setTimeout(() => {
              if(_this.self_reported_discomfort.length) {
                _this.hideSelfReportedDownloadBtn = true;
              }
              else {
                _this.hideSelfReportedDownloadBtn = false;
              }
              _this.setChartWidth = (_this.self_reported_discomfort.length < 2) ? _this.chart_options : _this.barChartOptions;
              _this.$refs.appendStandChart.renderChart(
                _this.selfdiscomfortdatacollection,_this.setChartWidth
              );
            }, 100);
          })
          .catch(() => {
            // 
          });
      },
      filterEquipmentData() {
        var _this = this;
        if (_this.listConfig.equipment_edate != "" || _this.listConfig.equipment_regionBy != "") {
          _this.classEquipment = true;
        }
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/equipment-recommendation",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : _this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              var decodedJson = _this.decodeAPIResponse(data.records.data);

              //Equipment Recommendations...
              _this.equipmentdatacollection.labels = [];
              _this.equipmentdatacollection.datasets[0].data = [];
              _this.equipmentdatacollection.datasets[0].backgroundColor = [];
              _this.equipment_recommendationsCSV = decodedJson.equipment_recommendationsCSV;
              _this.equipment_recommendations = decodedJson.equipment_recommendations;
              _this.equipment_recommendations.forEach(function (item) {
                // _this.equipmentdatacollection.labels.push(item.name + " (" + item.percent + "%)");
                _this.equipmentdatacollection.labels.push(item.name);
                _this.equipmentdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
                _this.equipmentdatacollection.datasets[0].backgroundColor.push("#F3C695");
              });
              _this.equipmentdatacollection.datasets[0].data.push(0);
            }
            else {
              _this.equipment_recommendationsCSV = [];
              _this.equipment_recommendations = [];
              _this.equipmentdatacollection.labels = [];
              _this.equipmentdatacollection.datasets[0].data = [];
              _this.equipmentdatacollection.datasets[0].backgroundColor = [];
            }
            setTimeout(() => {
              if(_this.equipment_recommendations.length) {
                _this.hideEquipmentDownloadBtn = true;
              }
              else {
                _this.hideEquipmentDownloadBtn = false;
              }
              _this.setChartWidth = (_this.equipment_recommendations.length < 2) ? _this.chart_options : _this.barChartOptions;
              _this.$refs.appendEquipment.renderChart(
                _this.equipmentdatacollection,_this.setChartWidth
              );
            }, 100);
          })
          .catch(() => {
            // 
          });
      },
      filterWorkArea() {
        var _this = this;
        if (_this.listConfig.workArea_edate != "" || _this.listConfig.workArea_regionBy != "") {
          _this.classWorkArea = true;
        }
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/work-area-assessed",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              //Work Area Assessed...
              var decodedJson = _this.decodeAPIResponse(data.records.data);

              _this.workareadatacollection.labels = [];
              _this.workareadatacollection.datasets[0].data = [];
              _this.work_area_assessed_HomeCSV = decodedJson.work_area_assessed_HomeCSV;
              _this.work_area_assessed_LabCSV = decodedJson.work_area_assessed_LabCSV;
              _this.work_area_assessed_OfficeCSV = decodedJson.work_area_assessed_OfficeCSV;
              _this.work_area_assessed_AnywhereCSV = decodedJson.work_area_assessed_AnywhereCSV;
              _this.work_area_assessed = decodedJson.work_area_assessed;
              _this.work_area_assessed.forEach(function (item) {
                // _this.workareadatacollection.labels.push(item.name + " (" + item.percent + "%)");
                _this.workareadatacollection.labels.push(item.name);
                _this.workareadatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              });
              _this.workareadatacollection.datasets[0].data.push(0);
            }
            else {
              _this.work_area_assessed_HomeCSV = [];
              _this.work_area_assessed_LabCSV = [];
              _this.work_area_assessed_OfficeCSV = [];
              _this.work_area_assessed_AnywhereCSV = [];
              _this.work_area_assessed = [];
              _this.workareadatacollection.labels = [];
              _this.workareadatacollection.datasets[0].data = [];
            }
            setTimeout(() => {
              if(_this.work_area_assessed.length) {
                _this.hideWorkAreaDownloadBtn = true;
              }
              else {
                _this.hideWorkAreaDownloadBtn = false;
              }
              _this.$refs.appendWorkArea.renderChart(
                _this.workareadatacollection,_this.chart_options
              );
            }, 100);
          })
          .catch(() => {
            // 
          });
      },
      filterComfortLevel() {
        var _this = this;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/self-comfort-level",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            if (data.success) {
              //Self-Reported Comfort Levels...
              var decodedJson = _this.decodeAPIResponse(data.records.data);

              _this.selfcomfortdatacollection.labels = [];
              _this.selfcomfortdatacollection.datasets[0].data = [];
              _this.self_comfort_levels_ComCSV = decodedJson.self_comfort_levels_ComCSV;
              _this.self_comfort_levels_DisCSV = decodedJson.self_comfort_levels_DisCSV;
              _this.self_comfort_levels = decodedJson.self_comfort_levels;
              _this.self_comfort_levels.forEach(function (item) {
                // _this.selfcomfortdatacollection.labels.push(item.name + " (" + item.percent + "%)");
                _this.selfcomfortdatacollection.labels.push(item.name);
                _this.selfcomfortdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              });
              _this.selfcomfortdatacollection.datasets[0].data.push(0);
            }
            else {
              _this.self_comfort_levels_ComCSV = [];
              _this.self_comfort_levels_DisCSV = [];
              _this.self_comfort_levels = [];
              _this.selfcomfortdatacollection.labels = [];
              _this.selfcomfortdatacollection.datasets[0].data = [];
            }
            setTimeout(() => {
              if(_this.self_comfort_levels.length) {
                _this.hideComfortLevelDownloadBtn = true;
              }
              else {
                _this.hideComfortLevelDownloadBtn = false;
              }
              _this.$refs.appendSelfComfort.renderChart(
                _this.selfcomfortdatacollection,_this.chart_options
              );
            }, 100);
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(() => {
            // 
          });
      },
      assignDefaultValues() {
        //Activity Region filter...
        this.listConfig.region_sdate = this.listConfig.start_date;
        this.listConfig.region_edate = this.listConfig.end_date;
        this.listConfig.region_regionBy = this.listConfig.regionBy;
        //Self-Reported Discomfort filter...
        this.listConfig.discomfort_sdate = this.listConfig.start_date;
        this.listConfig.discomfort_edate = this.listConfig.end_date;
        this.listConfig.discomfort_regionBy = this.listConfig.regionBy;
        //Equipment Recommendations filter...
        this.listConfig.equipment_sdate = this.listConfig.start_date;
        this.listConfig.equipment_edate = this.listConfig.end_date;
        this.listConfig.equipment_regionBy = this.listConfig.regionBy;
        //Work Area Assessed filter...
        this.listConfig.workArea_sdate = this.listConfig.start_date;
        this.listConfig.workArea_edate = this.listConfig.end_date;
        this.listConfig.workArea_regionBy = this.listConfig.regionBy;
        //Self-Reported Comfort Levels filter...
        this.listConfig.comfortLevel_sdate = this.listConfig.start_date;
        this.listConfig.comfortLevel_edate = this.listConfig.end_date;
        this.listConfig.comfortLevel_regionBy = this.listConfig.regionBy;
        
        if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
          this.changeStartDateRegion();
        }
      },
      updateDatePicker(comeFrom) {
        // Extend dataTables search
        $.fn.dataTable.ext.search.push(function (settings, data) {
          var min = $(".min-date-value").html();
          var max = $(".max-date-value").html();
          var createdAt = data[0] || 0; // Our date column in the table
          if (
            min == "" ||
            max == "" ||
            (moment(createdAt).isSameOrAfter(min) &&
              moment(createdAt).isSameOrBefore(max))
          ) {
            return true;
          }
          return false;
        });
        if (comeFrom == "Main") {
          this.assignDefaultValues();
          this.filterMainData();
          //Activity Region filter...
          this.filterActivityRegion();
          //Self-Reported Discomfort filter...
          this.filterSelfDiscomfort();
          //Equipment Recommendations filter...
          this.filterEquipmentData();
          //Work Area Assessed filter...
          this.filterWorkArea();
          //Self-Reported Comfort Levels filter...
          this.filterComfortLevel();
        }
      },
      // changeStartDate() {
      //   this.$nextTick(() => {
      //     this.isDisabled = false;
      //     this.disabledDates = {
      //       to: new Date(this.listConfig.start_date - 8640000),
      //       from: new Date(Date.now()),
      //     };
      //     if (
      //       this.listConfig.end_date != "" &&
      //       this.listConfig.end_date != null
      //     ) {
      //       this.updateDatePicker("Main");
      //     }
      //   });
      // },
      changeStartDate(newStartDate) {
        this.$nextTick(() => {
          const startDate = new Date(newStartDate);
          const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

          if (endDate && startDate > endDate) {
            this.$toast.error('"From" date cannot be greater than the "To" date.', {
                position: "top-right",
                duration: 5000,
              });
            this.listConfig.start_date = this.previousStartDate;
          } else {
            this.previousStartDate = newStartDate;

            // Old changeStartDate code
            this.isDisabled = false;
            this.disabledDates = {
              to: new Date(this.listConfig.start_date - 8640000),
              from: new Date(Date.now()),
            };
            if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
              this.updateDatePicker("Main");
            }
          }
        });
      },
      changeEndDate() {
        this.$nextTick(() => {
          this.updateDatePicker("Main");
        });
      },
      changeStartDateRegion() {
        this.$nextTick(() => {
          this.isDisabledRegion = false;
          this.disabledDates = {
            to: new Date(this.listConfig.region_sdate - 8640000),
            from: new Date(Date.now()),
          };
          if (
            this.listConfig.region_edate != "" &&
            this.listConfig.region_edate != null
          ) {
            this.updateDatePicker("");
          }
        });
      },
      customFormatter(date) {
        return moment(date).format("MMM DD, YYYY");
      },
    },
  };
</script>